let slider;

document.addEventListener('DOMContentLoaded', event => {
    slider = tns({
        container: '.carousel',
        items: document.querySelectorAll('.carousel li').length,
        mode: 'carousel',
        autoplay: false,
        autoWidth: true,
        controlsContainer: '#controls',
        onInit: () => {
            // Change nav position
            const mainSlider = document.querySelector('.tns-ovh');
            const sliderNav = document.querySelector('.tns-nav');
            sliderNav.parentNode.insertBefore(mainSlider, sliderNav);
        }
    });

    // Add eventlistener for smoothScrolling when menu-button is clicked
    const menuButton = document.querySelector('#menu-button');
    menuButton.addEventListener('click', event => {
        event.preventDefault();
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    });

    // Check windowWidth and place divs accordingly
    checkWindowWidth();
    window.addEventListener('resize', event => {
        checkWindowWidth();
    });
});

/**
 * Checks the windowWidth
 */
function checkWindowWidth() {
    if (window.innerWidth < 767) {
        const nextConcert = document.querySelector('#next-concert');
        nextConcert.remove();

        const headerBackground = document.querySelector('header .background');
        headerBackground.parentNode.insertBefore(nextConcert, headerBackground.nextSibling);
    } else {
        const nextConcert = document.querySelector('#next-concert');
        nextConcert.remove();

        const headerLogo = document.querySelector('header #logo a');
        headerLogo.parentNode.insertBefore(nextConcert, headerLogo.nextSibling);
    }
}

exports = slider;